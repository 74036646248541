<template lang="pug">
div.d-flex.flex-column
  HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" :mapModeButton="mapModeButton" :mapMode="mapMode" @updateMapMode="updateMapMode" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
  //- b-row.content-scrollable-sticky.m-0.justify-content-center(style="margin-top: -12px !important;")
  //-   b-col#nav-sticky-horizontal.content-scrollable-sticky-inner(cols='12' style='padding: 0 !important; height: 100% !important')
  //-     ul.d-flex
  //-       li
  //-         a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'all' ? 'current' : ''" @click="tabActive = 'all'")
  //-           span Toutes les baux
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingLeasesList || isLoadingLeasesListArchived || !headerLoaded')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des baux...
    div(v-else class="row w-100" style="margin:0px !important")
      sync-grid#overviewgrid(ref="overviewgrid" :uid="uid" :key="gridKey" :class="mapMode ? 'col-12 col-lg-6 d-none d-lg-block' : 'col-12'" style="padding: 0px !important;" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton")
      GmapMap.card-map.col-12.col-lg-6.order-sm-1(v-if='mapMode' ref='gmap' :zoom='6' :center='{lat:47, lng:2}' style='width: 50%; height: 100%;')
        GmapMarker(:key='index' v-for='(m, index) in dataFiltered.filter(elem=> elem.propertyLatitude && elem.propertyLongitude && elem.propertyLatitude!==0 && elem.propertyLongitude!==0)' :position='{lat:m.propertyLatitude, lng:m.propertyLongitude}' :clickable='true' :draggable='true')
  ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
    sidebar-lease-content(ref="sidebarLeaseContent" @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarLeaseContent from "@/components/billing/lease/SidebarLeaseContent";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarLeaseContent,
    SyncGrid,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "lease",
      uid: "leases",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],
      gridKey: 1,

      dateRanger: false,
      mapMode: false,
      mapModeButton: true,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un bail",
    };
  },
  beforeMount() {
    this.headerData = [
      // { field: 'propertyArea', headerName: 'Surface', resizable:true, sortable: true, maxWidth:220 ,cellRenderer: params => {
      //     return params.data.propertyArea ? params.data.propertyArea+' m<sup>2</sup>' : ''
      //   }
      // },
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "codeCRO",
        headerText: "Code",
        width: 100,
        maxWidth: 100,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "label",
        headerText: "Nom",
        width: 100,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "startDate",
        template: "dateYmdTemplate",
        headerText: "Date de début",
        width: 125,
        maxWidth: 125,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "endDate",
        template: "dateYmdTemplate",
        headerText: "Date de fin",
        width: 125,
        maxWidth: 125,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "leaseType.label",
        headerText: "Type de bail",
        width: 160,
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "periodicity.label",
        headerText: "Périodicité",
        width: 160,
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "propertyType.label",
        headerText: "Type de bien",
        width: 160,
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "tenantName",
        headerText: "Locataire",
        width: 160,
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "landlordName",
        headerText: "Bailleur",
        width: 160,
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "paymentDay",
        headerText: "Jour de paiement",
        width: 60,
        minWidth: 60,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "number",
        field: "rentHcHt",
        headerText: "Loyer HC/HT",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        allowFiltering: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        showFooterSum: true,
      },
      {
        type: "string",
        field: "tvaRent.label",
        headerText: "Tx de TVA du loyer",
        width: 160,
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "number",
        field: "chargeHt",
        headerText: "Charges HT",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        allowFiltering: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        showFooterSum: true,
      },
      {
        type: "string",
        field: "tvaCharge.label",
        headerText: "Tx de TVA des charges",
        width: 160,
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "number",
        field: "securityDeposit",
        headerText: "Dépôt de garantie",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        allowFiltering: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        showFooterSum: true,
      },
      {
        type: "string",
        field: "propertyAddress",
        headerText: "Adresse du bien",
        width: 160,
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "leasesList",
      "leasesListArchived",
      "isLoadingLeasesList",
      "isLoadingLeasesListArchived",
    ]),
    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.archive
          ? this.$store.getters[this.name + "sListArchived"].filter((elem) =>
              elem[this.name + "Tags"].find((tag) =>
                this.tagsSelected.includes(tag.id)
              )
            )
          : this.$store.getters[this.name + "sList"].filter((elem) =>
              elem[this.name + "Tags"].find((tag) =>
                this.tagsSelected.includes(tag.id)
              )
            );
      } else {
        return this.archive
          ? this.$store.getters[this.name + "sListArchived"]
          : this.$store.getters[this.name + "sList"];
      }
    },
    dataFiltered() {
      if (this.tabActive == "all") {
        if (this.tagsSelected.length) {
          return this.archive
            ? this.$store.getters[this.name + "sListArchived"].filter((elem) =>
                elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                )
              )
            : this.$store.getters[this.name + "sList"].filter((elem) =>
                elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                )
              );
        } else {
          return this.archive
            ? this.$store.getters[this.name + "sListArchived"]
            : this.$store.getters[this.name + "sList"];
        }
      }
    },
  },
  async created() {
    await this.getLeases({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
      }
      this.indexSearch++;
      this.headerLoaded = true;
    });
  },
  mounted() {
    this.viewMap = false;
  },
  methods: {
    ...mapActions(["getLeases", "getLeasesArchived"]),
    formatCurrency,
    setColumnsByObject,
    async changeTab(tab) {
      this.tabActive = tab;
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({ path: "/new-lease" });
    },
    updateMapMode(val) {
      this.mapMode = val;
      this.getLeases({});
      this.getLeasesArchived({});
      this.$nextTick(() => {
        this.gridKey++;
      });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getLeasesArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getLeases({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      if (this.archive) {
        this.$refs["sidebarLeaseContent"].getLeaseArchivedDetails(
          event.rowData.id,
          true
        );
      } else {
        this.$refs["sidebarLeaseContent"].getLeaseDetails(event.rowData.id);
      }
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
